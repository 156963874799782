import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import {Card} from 'primereact/card';
import {vaultService} from '../services/VaultServices';
import {DataTable} from 'primereact/datatable';
import {Column} from 'primereact/column';
import {ProgressSpinner} from 'primereact/progressspinner';
import {MobileView, BrowserView} from 'react-device-detect';
import {config} from '../services/config';
import * as signalR from '@microsoft/signalr';

export class Dashboard extends Component {
    constructor() {
        super();
        this.state = { 
            dataTableValue:[],
            defaultMLImagePath: 'assets/images/masterlock/',
            defaultImgExt: '.svg',            
            totalRecords: 0,
            vault:null,
            visible: false,
            sortKey: null,
            sortOrder: null,
            domainList: [],
            expandedRows: null,  
            dashBoardTotals:[{}],
            orgTimeZone: null,
            recentMessage: null,
            hubConnection: null,
            data: null
        };

        this.onSortChange = this.onSortChange.bind(this);
        this.detailTemplate = this.detailTemplate.bind(this);
        this.imageTemplate = this.imageTemplate.bind(this);
        this.rowClassName = this.rowClassName.bind(this);
    }
    componentDidMount() {
        let user = sessionStorage.getItem("user");
        if (user) {
                // console.log(user);
                let x = JSON.parse(user);
                this.setState(() => ({
                userId: x.userId,
                token: x.token,
                domainId: x.domainId,
                orgId: x.orgId
                }));
                
                if (!x.acceptedEula || x.userRole === 3){                    
                    this.props.handleLogout();
                } else {

                /*setup the signalr pipe*/                
                // const hubConnect = new signalR.HubConnectionBuilder()
                //     .configureLogging(signalR.LogLevel.Debug)
                //     .withUrl(config.apiUrl + '/DataHub', { accessTokenFactory: () => x.token })                    
                //     .withAutomaticReconnect()
                //     .build();
          
                // hubConnect.start()
                //     .then(() => console.log('Connection successful!'))
                //     .catch(err => console.log('Error while establishing connection :('));
          
                // hubConnect.on('DataUpdated', (data) => {
                //     //this.setState({ dataTableValue: data });
                //     this.setState({data: data});
                // });
                /*setup the signalr pipe end*/


                this.setState({ loading: true });
//                                hubConnection: hubConnect });               
                
                vaultService.fetchTop10VaultEvents(x.userId, x.token).then(data => this.setState({dataTableValue: data.itemResult,
                                                                                             totalRecords: data.itemResult.length,
                                                                                             orgTimeZone: 
                                                                                             data.itemResult.length ? 
                                                                                              data.itemResult[0].orgTimeZone
                                                                                             : "NONE"})); 
                                                                                             
                vaultService.fetchDashboardTotals().then(data => this.setState({dashBoardTotals: data.dashBoardTotals}));
                this.setState({loading: false}); 
                }
        }                   
    }
    onSortChange(event) {
        let value = event.value;

        if (value.indexOf('!') === 0)
            this.setState({sortOrder: -1, sortField:value.substring(1, value.length), sortKey: value});
        else
            this.setState({sortOrder: 1, sortField:value, sortKey: value});
    }    

    detailTemplate(data) {                         
        // console.log("data: ", data);
        if(data.event === "VAULT EDITED") {
            if(data.physicalLocation !== data.previousPhysicalLocation) {
                return(<span>Description Edited</span>);
            } else {
                return(<span>Codes Edited</span>);
            }
        } else if(data.event ==="USER ASSIGNED TO VAULT") {
            return(<span>{data.user} assigned to vault</span>);
        } else if(data.event ==="USER VAULT ASSIGNMENT REMOVED") {
            return(<span>{data.user} unassigned from vault</span>);
        } else if(data.event ==="MORNING/NIGHT CODES REQUESTED") {
            return(<span>Morning/Night Codes requested for {data.openCloseCodesEffectiveDate} - Justification: {data.tempCodeJustification}</span>);
        } else if(data.event ==="VAULT DOMAIN ASSIGNMENT MODIFIED") {
            return(<span>{data.domain} domain assigned</span>);
        } else if(data.event ==="TEMP CODE REQUEST - ENTRY") {
            return(<span>Temp Code Requested - Justification: {data.tempCodeJustification}</span>);
        } else if(data.event ==="VAULT ACCESS ACTIVITY") {
            return(<span>{data.tempCodeJustification}</span>);
        } else if(data.event ==="VAULT ACCESS CODES UPDATED") {
            return(<span>Access Codes Updated</span>);
        } else if(data.event ==="INVALID PASSCODE ATTEMPTED") {
            return(<span>Invalid Passcode Attempted</span>);
        }
    }

    imageTemplate(data) {
        let imgCheck = data.vaultModel;
        
        if (imgCheck.length){
            return  (
                    <div><img  style={{width:"100%",margin:"auto"}} src={this.state.defaultMLImagePath + data.vaultModel + this.state.defaultImgExt}
                            alt={data.vaultModel}
                            width="30px"
                            height="30px" />
                    </div>
            );
        } else {
            return  (
                <div style={{padding:'1em',width:'100%'}}><img src="/assets/layout/images/warning.png"
                        alt={data.vaultModel}
                        width="32px"
                        height="32px" />
                </div>
        );
        }
    }
    rowClassName(rowData) {
        let event = rowData.event;
        
        return {'p-highlight' : (event.includes('INVALID')||event.includes('NOT AUTHORIZED'))};
    }

/*================================================================================================================*/               
    render() {
        const { loading, dashBoardTotals } = this.state;  
        //console.log("message: ", recentMessage);
        return (
            <div className="flexi-grid">
            <div className="p-col-12">
                <div className="card card-w-title">
                    <h1><i className="pi pi-chart-line"/>	Dashboard</h1>
                    {/* <h2>Msg = {recentMessage}</h2>                         */}
                    <MobileView>
                    <div className="p-grid">
                        <div className="p-col-12">
                            <Card>
                                <h2 style={{marginLeft:'1em'}}><strong><Link to="/vaultadmin">{dashBoardTotals[0].totalVaults} Devices</Link></strong></h2>
                                <h2 style={{marginLeft:'1em'}}><strong><Link to="/useradmin">{dashBoardTotals[0].totalAssociates} Associates</Link></strong></h2>
                                <h3 style={{marginLeft:'4em'}}>{dashBoardTotals[0].totalAdmins} Administrators</h3>
                                <h3 style={{marginLeft:'4em'}}>{dashBoardTotals[0].totalAPUsers} AP Users</h3>
                                <h3 style={{marginLeft:'4em'}}>{dashBoardTotals[0].totalSocUsers} SOC Mode Users</h3>
                                <h3 style={{marginLeft:'4em'}}>{dashBoardTotals[0].totalMobileOnlyUsers} Mobile Only Users</h3>
                                <h2 style={{marginLeft:'1em'}}><strong><Link to="/domains">{dashBoardTotals[0].totalDomains} Domains</Link></strong></h2>
                            </Card>
                        </div>
                        <div className="p-col-12">
                        {
							    loading && <ProgressSpinner/> 
						}
                            <Card>
                            <h2><strong>Most Recent Device Activity</strong></h2>
                                ({this.state.orgTimeZone})
                                
                                <DataTable id="vaultEvents"
                                        style={{fontSize:"80%"}}
                                        value={this.state.dataTableValue}
                                        rows={10} responsive={true}
                                        selection={this.state.dataTableSelection} selectionMode="single"
                                        resizableColumns={true}                                         
                                        ref={(el) => { this.dt = el; }}
                                        expandedRows={this.state.expandedRows} 
                                        onRowToggle={(e) => this.setState({expandedRows:e.data})}
                                        rowExpansionTemplate={this.rowExpansionTemplate} dataKey="evtId"
                                        rowClassName={this.rowClassName}>                                                                                  
                                    <Column body={this.imageTemplate} style={{width: '4em', textAlign: 'left',margin:0,padding:0}}/>                            
                                    <Column field="eventDate" header="Event Date" sortable={true} />
                                    <Column field="assetTag" header="Device ID" sortable={true}/>  
                                    <Column field="physicalLocation" header="Device Description" sortable={true}/>           
                                    <Column field="triggeredBy" header="Triggered By" sortable={true}/>
                                    <Column body={this.detailTemplate} header="Event Detail" sortable={true}/>
                                </DataTable>                        
                                
                            </Card>
                        </div>
                    </div>
                    </MobileView>
                    <BrowserView>
                    {/* <p>Data: {this.state.data}</p> */}
                    <div className="p-grid">
                        <div className="p-col-3">
                            <Card>
                                <h2 style={{marginLeft:'1em'}}><Link to="/vaultadmin"><strong>{dashBoardTotals[0].totalVaults} Devices</strong></Link></h2>
                                <h2 style={{marginLeft:'1em'}}><Link to="/useradmin"><strong>{dashBoardTotals[0].totalAssociates} Associates</strong></Link></h2>
                                <h3 style={{marginLeft:'4em'}}>{dashBoardTotals[0].totalAdmins} Administrators</h3>
                                <h3 style={{marginLeft:'4em'}}>{dashBoardTotals[0].totalAPUsers} AP Users</h3>
                                <h3 style={{marginLeft:'4em'}}>{dashBoardTotals[0].totalSocUsers} SOC Mode Users</h3>
                                <h3 style={{marginLeft:'4em'}}>{dashBoardTotals[0].totalMobileOnlyUsers} Mobile Only Users</h3>
                                <h2 style={{marginLeft:'1em'}}><Link to="/domains"><strong>{dashBoardTotals[0].totalDomains} Domains</strong></Link></h2>
                            </Card>
                        </div>
                        <div className="p-col">
                        {
							    loading && <ProgressSpinner/> 
						}
                            <Card>
                                <h2><strong>Most Recent Device Activity</strong></h2>
                                ({this.state.orgTimeZone})  

                                <DataTable id="vaultEvents"
                                        value={this.state.dataTableValue} 
                                        rows={20} responsive={true}
                                        selection={this.state.dataTableSelection} selectionMode="single"
                                        resizableColumns={true} emptyMessage="No records found"
                                        ref={(el) => { this.dt = el; }} autoLayout dataKey="evtId" rowClassName={this.rowClassName}>                                                                                  
                                    <Column body={this.imageTemplate} style={{width: '4em', textAlign: 'left',margin:0,padding:0}}/>                            
                                    <Column field="eventDate" header="Event Date" style={{width:"15%",textAlign:"center"}} sortable="custom" sortFunction={this.onSortFunction} />
                                    <Column field="assetTag" header="Device ID" sortable={true} headerStyle={{textAlign:"left"}} style={{width:"10%"}}/>
                                    <Column field="physicalLocation" header="Device Description" headerStyle={{textAlign:"left"}} sortable={true}/>                            
                                    <Column field="triggeredBy" header="Triggered By" sortable={true} headerStyle={{textAlign:"left"}}/>
                                    <Column body={this.detailTemplate} header="Event Detail" sortable={true} headerStyle={{textAlign:"left"}}/>
                                </DataTable>                 
                            </Card>
                        </div>
                    </div>
                    </BrowserView>
                </div>
                

                
            </div>
        </div>
           
        );
    }
}